import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

export function getBaseUrl() {

 

  if (!environment.production)
     return 'https://api.comercial.curbe.com.ec/';
    //  return 'http://192.168.1.81:5000/';
    //  return 'http://192.168.3.36:5000/'; //Carlos
    //  return 'http://192.168.3.9:5000/'; //Carlos
    //return 'https://apicomercialtest.curbe.com.ec/';
    //return 'http://192.168.1.135:5000/'; //Johny IP
     //return 'http://localhost:5000/'; // Carlos IP
    //return 'http://localhost:5000/'; // Pancho IP
    // return 'http://25.105.138.136:5000'
  else
  return 'https://api.comercial.curbe.com.ec/';
  //return 'https://apicomercialtest.curbe.com.ec/'
  // return document.getElementsByTagName('base')[0].href;


}
const providers = [{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
