import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private router: Router, private msg: NzMessageService) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const token = localStorage.getItem('tk');

    let request = req;
    let contentType = 'application/json';
    let headers:any = {
      'Authorization': `Bearer ${ token }`      
    };

    
    // headers['Content-Type'] = contentType;

    if (request.headers.has('Content-Type')){      
      headers['Content-Type'] = request.headers.get('Content-Type');
    }

    if (token) {
      request = req.clone({
        setHeaders: headers
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        
        if (err.status === 401) {
          //limpar cache  
          localStorage.clear();
          //
          if(err.error)
            this.msg.error(`Error: ${err.error.message}`);
          else
            this.msg.error(`Error Usuario no Autorizado`);          
          //this.router.navigate(['/login']);
          if(this.router.url != '/login')        
            window.location.href = "/login";
        }
        else if(err.status === 500){
          this.msg.error(`Error en el servidor`); 
        }
        return throwError( err );
      })
    );
  }
}
