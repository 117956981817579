import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../../models/usuario';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user:Usuario | undefined;
  token:string | undefined;
  private baseUrl :string;


  constructor(private router: Router,
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient) {
      console.warn('Auth started');
      this.getCredentials();
      this.baseUrl = baseUrl;
  }

  login(username:string,password:string):Observable<any> {
    let login = {
      username,
      password,
    }
    return this.http.post(`${this.baseUrl}api/auth/login/`,login);
  }

  logout(){
    this.user = undefined;
    this.token = undefined;
    localStorage.removeItem("data");
    localStorage.removeItem("tk");
    this.router.navigate(['/login']);
  }

  setCredentials(credentials:any){
    localStorage.setItem("data",JSON.stringify(credentials.usuario));
    localStorage.setItem("tk",credentials.token);
    this.getCredentials();
  }

  getCredentials(){

    let usuario = localStorage.getItem("data");
    let token = localStorage.getItem("tk");

    
    if(!usuario || !token ){
      
      this.user = undefined;
      this.router.navigate(['/login']);
    }else{
      
      this.user = JSON.parse(usuario);
      this.token = token;
    }
  }


  isLogin():Observable<any>{
    return of({authenticated:this.user && this.token?true:false});
  }

  getToken(){
    let tk = localStorage.getItem("tk");
    if(!tk){
      this.token = undefined;
    }
    else{
      this.token = tk;
     }
     return this.token;
  }
}
