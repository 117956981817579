import { Component } from '@angular/core';
import { AuthService } from './core/services/auth-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CurbeInventarios';


  storedTheme: any = localStorage.getItem('theme-color');

  constructor(private authservice:AuthService){}

  setTheme(theme: string) {
    localStorage.setItem('theme-color', '#37ADC9');
    this.storedTheme = localStorage.getItem('theme-color')
  }
}
